.main {
  min-height: 200px;

  h2 {
    padding: 6rem 6rem 2vw;
    font-size: 24rem;
    font-weight: 800;
    color: var(--primary-color);

    animation: pulse 1.1s;
    animation-fill-mode: forwards;
  }
}
footer {
    background: transparent;
    padding: 2rem 0;
    text-align: center;
    font-size: 3vw;
    transition: var(--transInOut);

    &:hover {
        background: var(--primary-color);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .footerLogo {
        font-size: 4vw;
        font-weight: 500;
        margin-bottom: 1vw;
        display: inline-block;
    }
    
    .footerSocials {
        display: flex;
        justify-content: center;
        gap: 4vw;
        margin-bottom: 2vw;

        li a {
            padding: 0.5vw 0.8vw;
            border-radius: 3rem;
            display: flex;
            border: 1px solid transparent;
            transition: all 0.4s ease-out;

            &:hover {
                background: var(--active-color);
                border-color: var(--secondary-color);
                
                button {
                    color: var(--primary-color);
                }
            }

            button {
                font-size: 6vw;
                background: transparent;
            }
        }
    }

    .footerCopyright {
        margin-bottom: 4vw;
        color: var(--secondary-color);
    }
}

/* media queries md */
@media screen and (min-width: 600px) {
    footer {
        font-size: 6rem;

        .footerSocials li a button {
            font-size: 4vw;
        }
    }    
}

/* media queries lg */
@media screen and (min-width: 1024px) {
    footer {
        .footerSocials li a button {
            font-size: 2vw;
        }
    }
}
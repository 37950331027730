.form {
  width: 100%;
  padding: 20rem;
  margin: 4vw auto;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 3vw;
    justify-content: space-between;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      overflow: hidden;
      clear: both;
      position: relative;

      animation: fadeIn 3s;
      animation-fill-mode: forwards;


      * {
        border-radius: 2vw;
        border: 1px solid rgba(31, 31, 56, 0.10);
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        width: 100%;
        height: 50px;
        font-size: 3vw;
        padding: 0 20px;
        margin: 4px 0;
        box-sizing: border-box;
        background: transparent;
        transition: var(--transOut);

        &:hover {
          background: var(--primary-color);
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        }
      }

      *:focus {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-color: rgba(31, 31, 56, 0.50);
      }

      textarea {
        padding: 20px;
        min-height: 150px;
      }

      button {
        width: 60%;
        letter-spacing: 1vw;
        text-transform: uppercase;
        float: right;
        text-align: center;
        margin-right: 2vw;

        &:hover {
          background: var(--active-color);
        }
      }
    }
  }
}

/* media queries md */
@media screen and (min-width: 600px) {
  .form {
    width: 70vw;
    margin: 0 auto;

    ul > li {
      * {
        font-size: 2.2vw;
      }

      button {
        letter-spacing: 0.6vw;
      }
    }
  }
}

/* media queries lg */
@media screen and (min-width: 1024px) {
  .form {
    max-width: 780px;

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-flow: row wrap;
      gap: 1vw;
      justify-content: space-between;

      li {
        * {
          font-size: 1.4vw;
        }

        &:nth-child(1),
        &:nth-child(2) {
          flex: 0 1 48%;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          flex: 0 1 100%;
        }

        button {
          width: 48%;
          text-transform: uppercase;
          text-align: center;
          margin-right: 0;
          font-size: 1vw;
          font-weight: 900;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

/* imports */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/* reset attributes */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

/* variables assignment */
:root {
  --bg-color: #fff;
  --active-color: #ff5e00;
  --primary-color: #4db5ff;
  --secondary-color: #1f1f38;
  
  --transOut: all 0.3s ease-out;
  --transInOut: all 2s ease-in-out;

  --container-width-lg: 75vw;    
  --container-width-md: 86vw;    
  --container-width-sm: 90vw;    
}


/* html tags styles */
html {
  font-size: 0.4vmin;
  scroll-behavior: smooth;
  /* overflow: -moz-hidden-unscrollable; */
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  
}

body {
  margin: 0;
  font: 300 1.5vw/1.4 'Poppins', sans-serif;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none; 
}
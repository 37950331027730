.works {
  width: 100%;
  padding: 20rem 6rem;
  margin: 4vw auto;

  h2 {
    padding-left: 24rem;
  }

  .card {
    display: grid;
    grid-template-columns: 1fr;
    // justify-content: space-around;
    gap: 2rem;
    margin: 0 auto;
    padding: 12rem;
    padding-bottom: 16rem;
    background: transparent;

    animation: fadeIn 3s;
    animation-fill-mode: forwards;

    .swiper {
      background: transparent;
      padding: 6rem;
      margin: 2rem;
      border-radius: 2rem;
      border: 1px solid transparent;
      transition: var(--transOut);

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
      }

      .preview {
        aspect-ratio: 1/0.75;
        border-radius: 1.5rem;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      }

      h3 {
        margin: 1.2rem 0 2rem;
        font-size: 16rem;
      }

      .cta {
        display: flex;
        gap: 2rem;
        margin-bottom: 1.2rem;

        a {
          padding: 1.2rem 1.8rem;
          border-radius: 1.5rem;
          border: 1px solid transparent;
          background: rgba(255, 255, 255, 0.3);
          transition: var(--transOut);

          

          button {
            background: transparent;

            &:hover {
              color: #fff;
            }
          }

          &:hover {
            background: var(--active-color);
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          }
        }
      }
    }
  }
}

/* media queries md */
@media screen and (min-width: 600px) {
  .works {
    width: 70vw;
    margin: 0 auto;

    .card {
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;

      .swiper {
        .cta a {
          padding: 1rem 2rem;
        }

        h3 {
          font-size: 8rem;
        }
      }
    }
  }
}

/* media queries lg */
@media screen and (min-width: 1024px) {
  .works .card {
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    .swiper .cta a {
      padding: 0 3.5rem;
    }
  }
}

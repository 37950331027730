nav {
  display: flex;
  flex-direction: column;
  width: 85vw;
  min-width: 300px;
  min-height: 180px;
  margin: 2vw auto;
  z-index: 5;
  position: block;
  border-radius: 0rem;
  padding-left: 5vw;
  overflow: hidden;
  border: 0.3px solid transparent;

  .icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    overflow: -moz-hidden-unscrollable;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */

    color: var(--secondary-color);
    text-align: left;
    font-size: 12rem;
    position: relative;
    width: 100%;
    object-fit: cover;
    transition: all 0.5s ease-out;
    border-radius: 3.5rem;
    margin: auto;

    &:hover {
      color: var(--primary-color);
      background: rgba(255, 255, 255, 0.25);

      .iconValue {
        opacity: 0.35;
      }

      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      font-size: 14rem;
      font-weight: 500;
      letter-spacing: 2px;
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
      opacity: 0;
      transition: all 0.3s ease-out;
      margin: auto;
    }
  }

  .icon.navHome::after {
    content: 'HOME';
  }

  .icon.navAbout::after {
    content: 'ABOUT';
  }

  .icon.navWorks::after {
    content: 'WORKS';
  }

  .icon.navContact::after {
    content: 'CONTACT';
  }
}

/* media queries md */
@media screen and (min-width: 600px) {
  nav {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // align-content: center;
    max-width: 60vw;
    // height: 6vw;
    min-height: 10px;
    // max-height: 40px;
    border-radius: 3.5rem;
    top: 1rem;
    padding-left: 0px;
    padding: 10rem;
    transition: all 0.8s ease-in-out;
    border-radius: 20rem;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

    &:hover {
      border: 0.3px solid transparent;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 2.6vw;
      // align-self: center;

      .iconValue {
        display: flex;
        align-items: baseline;
      }

      &:hover {
        color: var(--primary-color);
        background: transparent;

        .iconValue {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }

      &::after {
        font-size: 2vw;
        font-weight: 800;
        bottom: auto;
        letter-spacing: normal;
      }
    }
  }
}

/* media queries lg */
@media screen and (min-width: 1024px) {
  nav {
    .icon {
      font-size: 2vw;

      &::after {
        letter-spacing: 2px;
      }
    }
  }
}

//

// nav {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   width: 85vw;
//   min-width: 300px;
//   min-height: 140px;
//   margin: 2vw auto;
//   z-index: 5;
//   position: block;
//   border-radius: 0rem;
//   padding-left: 5vw;

//   border: 0.3px solid transparent;
// }

// .icon {
//   color: var(--secondary-color);
//   text-align: left;
//   font-size: 12rem;
//   position: relative;
//   width: 100%;
//   object-fit: cover;
//   transition: all 0.5s ease-out;
//   border-radius: 3.5rem;
//   margin: auto;
// }

// .icon:hover {
//   color: var(--primary-color);
//   background: rgba(255, 255, 255, 0.25);
// }

// .icon:hover .iconValue {
//   opacity: .75;
// }

// .icon:hover:after {
//   opacity: 1;
// }

// .icon::after {
//   content: '';
//   font-size: 16rem;
//   font-weight: 500;
//   letter-spacing: 2px;
//   position: absolute;
//   bottom: 0;
//   display: block;
//   width: 100%;
//   text-align: center;
//   opacity: 0;
//   transition: all 0.3s ease-out;
//   margin: auto;
// }

// .icon.home::after {
//   content: 'HOME';
// }

// .icon.about::after {
//   content: 'ABOUT';
// }

// .icon.works::after {
//   content: 'WORKS';
// }

// .icon.contact::after {
//   content: 'CONTACT';
// }

// /* media queries md */
// @media screen and (min-width: 600px) {
//   nav {
//     flex-direction: row;
//     align-content: center;
//     max-width: 60vw;
//     height: 6vw;
//     min-height: 10px;
//     max-height: 40px;
//     border-radius: 3.5rem;
//     top: 1rem;
//     padding-left: 0px;
//   }
//   .icon {
//     text-align: center;
//     font-size: 2.6vw;
//   }
//   .icon:hover {
//     color: var(--primary-color);
//     background: transparent;
//   }

//   .icon:hover .iconValue {
//     opacity: 0;
//   }

//   .icon:hover:after {
//     opacity: 1;
//   }
//   .icon::after {
//     font-size: 2vw;
//     font-weight: 800;
//   }

// }

// /* media queries lg */
// @media screen and (min-width: 1024px) {
//   .icon {
//     font-size: 2vw;
//   }
//   .icon::after {
//     letter-spacing: 2px;
//   }
// }

.about {
  width: 100%;
  padding: 20rem;
  margin: 4vw auto;

  display: flex;
  flex-flow: column wrap;

  article {
    padding: 0 0 4vw;
    font-size: 12rem;
    font-weight: 300;

    animation: fadeIn 3s;
    animation-fill-mode: forwards;
  }
}

/* media queries md */
@media screen and (min-width: 600px) {
  .about {
    display: flex;
    flex-flow: column wrap;
    width: 70vw;
    margin: 0 auto;

    article {
      padding: 0 0 4vw;
      font-size: 9rem;
      font-weight: 300;
    }
  }
}

/* media queries lg */
@media screen and (min-width: 1024px) {
  .about {
    max-width: 780px;

    article {
      font-size: 6rem;
    }
  }
}

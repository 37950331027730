.home {
  display: flex;
  flex-flow: column wrap;
  padding: 20rem 6rem;

  width: 100%;
  padding: 10vw;
  margin: 4vw auto;

  animation: fadeIn 4s;
  animation-fill-mode: forwards;

  a {
    padding: 0px 3px 8px 2px;

    button {
      outline: none;
      background: transparent;
      transition: all 0.3s ease-in-out;
      text-shadow: 0 0 5px transparent;
      padding: 10px;
      border-radius: 3rem;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        color: var(--primary-color);
      }
    }

    .active:hover {
      background: var(--active-color);
      color: #fff;
    }
  }
}

/* media queries md */
@media screen and (min-width: 600px) {
  .home {
    width: 70vw;
    margin: 0 auto;
  }
}

/* media queries lg */
@media screen and (min-width: 1024px) {
  .home {
    max-width: 680px;
    font-size: 0.6vw;

    a button {
      font-size: 6rem;
    }
  }
}
